import { createBrowserRouter } from "react-router-dom";
import Sqribble from "../pages/sqribble";
import Home from "../pages";
import SqribbleMain from "../pages/sqribble/main";
import VertShock from "../pages/vert-shock";
import VittaHyaluronic from "../pages/vitta-hyaluronic";
import VitaProNobis from "../pages/vita-pro-nobis";

export default function Routes() {
  const router = createBrowserRouter([
    { path: "/", element: <Home /> },
    { path: "/sqribble", element: <Sqribble /> },
    { path: "/sqribble/main", element: <SqribbleMain /> },
    { path: "/vert-shock", element: <VertShock /> },
    { path: "/vitta-hyaluronic", element: <VittaHyaluronic /> },
    { path: "/vita-pro-nobis", element: <VitaProNobis /> },
  ]);
  return router;
}