import React, { useEffect } from "react"
import Modal from "../../components/Modal"

export default function Sqribble() {
  const linkAffiliate = "https://0fe17iqnu0cmvp7j3dmwm9oodp.hop.clickbank.net/?&traffic_source=google&traffic_type=paid";
  return (
    <>
      <div className="w-full min-h-screen bg-orange-400">
        <div id="cta" className="h-16 flex flex-col justify-center items-center bg-red-400">
          <span className="text-center font-nunito font-semibold text-lg lg:text-[28px] text-white">Join 40,000 + Members Using Sqribble! <span className="font-extrabold">LIMITED TIME SALE! <span className="text-yellow-300">50% OFF NOW!</span></span></span>
        </div>
        <header className="w-full h-screen flex flex-col items-center bg-[url(https://d3tb5hw0zfiw46.cloudfront.net/img/sqribble/bg-orange-abstract.jpg)] bg-cover lg:hidden">
          <div 
            id="headline"
            className="w-[90%] m-6"
          >
            <div className="w-full flex justify-center items-center mb-6">
              <img 
                className="w-[70%] lg:w-[300px]"
                src="https://d3tb5hw0zfiw46.cloudfront.net/img/sqribble/logo.png" 
                alt="logo" 
              />
            </div>
            <h1 className="text-center text-[26px] lg:text-[36px] font-roboto font-[500]">
              Creates AMAZING eBooks & Reports In 5 MINUTES Without Typing Any Words!
            </h1>
          </div>
          <div id="vsl w-[98%]">
            <iframe 
              className="w-full h-[200px]"
              src="https://d3tb5hw0zfiw46.cloudfront.net/img/sqribble/vsl.mp4" 
              title="YouTube video player" 
              frameBorder="0" 
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerPolicy="strict-origin-when-cross-origin" 
              allowFullScreen
            ></iframe>
          </div>
          <div className="w-full mt-6 flex flex-col justify-center items-center">
            <p className="w-[90%] text-center font-nunito font-extrabold text-white text-xl mb-1 leading-relaxed drop-shadow">Get Sqribble For Just <del>$197</del></p>
            <p className="text-3xl text-center font-nunito font-extrabold text-white mb-4 drop-shadow">$67 Today!</p>
            <a
              id="btn-lp"
              className="bg-[#3942DF] w-[60%] h-20 text-white text-bold text-xl font-nunito font-bold rounded-3xl shadow-2xl shadow-orange-700 flex justify-center items-center"
              href="https://0fe17iqnu0cmvp7j3dmwm9oodp.hop.clickbank.net/?&traffic_source=google&traffic_type=paid"
            >
              Get Discount Now
            </a>
          </div>
        </header>
        {/* DESKTOP */}
        <header className="hidden w-full h-full lg:flex flex-col justify-center items-center bg-[url(https://d3tb5hw0zfiw46.cloudfront.net/img/sqribble/bg-header.png)] bg-cover pb-20">
          <div 
            id="headline"
            className="w-full m-6"
          >
            <div className="w-full flex justify-center items-center mb-6">
              <img 
                className="w-[60%] lg:w-[300px]"
                src="https://d3tb5hw0zfiw46.cloudfront.net/img/sqribble/logo.png" 
                alt="logo" 
              />
            </div>
            <h1 className="w-[50%] text-center text-[26px] lg:text-[48px] font-roboto font-extrabold m-auto">
              Creates AMAZING eBooks & Reports <span className="underline">In 5 MINUTES</span> Without Typing Any Words!
            </h1>
          </div>
          <div id="vsl" className="w-[50%] h-[500px] bg-orange-400">
            <iframe 
              className="w-full h-full bg-white"
              src="https://d3tb5hw0zfiw46.cloudfront.net/img/sqribble/vsl.mp4" 
              title="YouTube video player" 
              frameBorder="0" 
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerPolicy="strict-origin-when-cross-origin" 
              allowFullScreen
            ></iframe>
          </div>
          <div className="w-full mt-6 flex flex-col justify-center items-center">
            <p className="w-[90%] text-center font-nunito font-extrabold text-white text-[34px] mb-1 leading-relaxed drop-shadow">Get Sqribble For Just <del>$197</del></p>
            <p className="text-[38px] underline text-center font-nunito font-extrabold text-white mb-4 drop-shadow">$67 Today!</p>
            <a 
              id="btn-lp"
              className="bg-[#3942DF] w-[400px] h-20 text-white text-bold text-[32px] font-nunito font-bold rounded-3xl mt-3 shadow-2xl shadow-orange-700 text-center flex justify-center items-center"
              href="https://0fe17iqnu0cmvp7j3dmwm9oodp.hop.clickbank.net/?&traffic_source=google&traffic_type=paid"
            >
              Get Discount Now
            </a>
          </div>
        </header>
        <main>

        </main>
        <footer className="w-full h-14 bg-[#3942DF] flex justify-center items-center text-white font-normal font-nanito">
          © {new Date().getFullYear()} Purchase Today
        </footer>
      </div>
      <Modal link={linkAffiliate} />
    </>
  )
}